//
//
//

import openData from './openData.vue'
import { getCorpByUser } from '@/api/modular/fileStream/documentManage'
export default {
  props: {
    openid: {
      type: String,
      default: ''
    }
  },
  watch: {
    openid: {
      handler(id) {
        if (id) this.setCorp()
      },
      immediate: true
    }
  },
  components: {
    openData
  },
  data() {
    return {
      corpId: '',
      isLoad: false
    }
  },
  methods: {
    setCorp() {
      getCorpByUser({ userIds: this.openid })
      .then(res => {
        if (res.code === 200) {
          const [{ corpId }] = res.data
          this.corpId = corpId
          this.isLoad = true
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }
}
